import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../styles/UserPage.css'; // Import the CSS for styling

const UserPage = () => {
  const location = useLocation();
  const { raspberryToken } = location.state || {};
  const [videoUrl, setVideoUrl] = useState('');
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);

  useEffect(() => {
    const fetchLastVideo = async () => {
      if (!raspberryToken) {
        console.error('No Raspberry Token provided');
        return;
      }

      try {
        const baseURL = 'https://get-latest-video-o7utgv2yoq-uc.a.run.app';
        const firebaseAuthToken = localStorage.getItem('firebaseAuthToken');
        if (!firebaseAuthToken) {
          console.error('No Firebase Auth Token available');
          return;
        }
        const response = await axios.get(`${baseURL}/get-last-video`, {
          params: { raspberry_token: raspberryToken },
          headers: {
            'Authorization': `Bearer ${firebaseAuthToken}`,
            'Content-Type': 'application/json',
          }
        });

        setVideoUrl(response.data.videoUrl);
      } catch (error) {
        console.error('Error fetching the last video:', error);
      }
    };

    fetchLastVideo();
  }, [raspberryToken]);

  const handleSpeedChange = (e) => {
    setPlaybackSpeed(parseFloat(e.target.value));
  };

  return (
    <div className="user-page-container">
      <h1>Your Video</h1>
      {videoUrl ? (
        <div className="video-container">
          <video
            controls
            src={videoUrl}
            ref={(videoElement) => {
              if (videoElement) {
                videoElement.playbackRate = playbackSpeed;
              }
            }}
            style={{ width: '100%' }}
          >
            Your browser does not support the video tag.
          </video>
          <div className="controls">
            <label htmlFor="speed">Playback Speed:</label>
            <input
              type="range"
              id="speed"
              min="0.5"
              max="10.0"
              step="0.1"
              value={playbackSpeed}
              onChange={handleSpeedChange}
            />
            <span>{playbackSpeed}x</span>
          </div>
        </div>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
};

export default UserPage;
