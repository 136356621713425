import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Import Firebase auth instance
import '../styles/Login.css'; // Import the CSS file

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();  // Using useNavigate hook

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://login-user-o7utgv2yoq-uc.a.run.app', 
        {
          username: username, 
          password: password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.message === 'Login successful') {
        setMessage('Login successful');
        console.log('Raspberry Token:', response.data.raspberry_token);

        // Store raspberry token for later use
        localStorage.setItem('raspberryToken', response.data.raspberry_token);

        // Authenticate the user with Firebase using the custom token
        const firebaseCustomToken = response.data.firebase_custom_token;

        signInWithCustomToken(auth, firebaseCustomToken)
          .then((user) => {
            // User successfully signed in with Firebase
            console.log('Firebase User:', user);

            // Store the Firebase Auth Token in localStorage
            user.user.getIdToken().then((firebaseAuthToken) => {
              localStorage.setItem('firebaseAuthToken', firebaseAuthToken);

              // Navigate to UserPage with the raspberry token
              navigate('/user', { state: { raspberryToken: response.data.raspberry_token } });
            });
          })
          .catch((error) => {
            console.error('Firebase sign-in error:', error);
          });
      } else {
        setMessage('Login failed');
      }
    } catch (error) {
      setMessage('Error occurred');
      console.error(error);
    }
  };

  return (
    <div className="login-container">
      <h1>Login Page</h1>
      <form onSubmit={handleLogin}>
        <input 
          type="text" 
          placeholder="Username" 
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default LoginPage;
